import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";



export default function Driverless() {
  return (
    <Layout pageTitle="Driverless Futures">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Driverless Futures - USI Projects</title>
        <meta name="description" content="Driverless Futures was a new component exhibition at the London Transport Museum in Covent Garden."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

        <section class="text-gray-700 body-font heading-container-driverless">
        <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Driverless Futures</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  London Transport Museum</p>
              </div>
          </section>
        </div>
      </section>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading">Overview</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl  ">Driverless
                  Futures was a new component exhibition at the London Transport Museum in Covent Garden. It was
                  Commissioned in 2017 by the Royal College of Art and the Museum as a partnership project for the Mayor
                  of London. It explored the potential impacts of driverless vehicle technology. It was supported by the
                  GATEway project (Greenwich Automated Transport Environment) & Helen Hamlyn Centre for Design.<br/><br/>

                  USI developed a series of utopian and dystopian themes that arose from an extensive community
                  engagement process on driverless futures. The design incorporated modular construction allowing for it
                  to be quickly assembled and disassembled at multiple venues. The system created therefore had to be as
                  flexible as possible to allow for the content to be displayed in a different spatial configuration
                  each time.<br/><br/>

                </p>

              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p  class="text-2xl font-bold uppercase ">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed  ">
                  Exhibitions<br/>Culture<br/>
                  Tourism<br/>Technology
                </p>
                  <p class="text-2xl font-bold uppercase ">Services</p>
                  <p class="my-4 text-gray-500 leading-relaxed  ">Culture<br/>
                    Public Engagement<br/>Technical Design<br/>Project Management</p>
                  <p class="text-2xl font-bold uppercase  ">Location</p>
                  <p class="my-4 text-gray-500 leading-relaxed  "> London Transport
                    Museum</p>
              </div>
            </div>
          </section>
        </div>
      </section>




      <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/Transport_Museum-Clean_MpW6wOH0grqH.jpg" alt=""/>


      <div id="image">
        <img data-scroll data-scroll-speed="2" data-scroll-target="#image" class="w-full md:pl-20 md:pr-20"
          src="https://ik.imagekit.io/usi/project-page/transportmuseum_4ENLDCmFQ.jpg" alt=""/>
      </div>

      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap ">
              <div class="w-full md:px-40 xl:w-6/12 py-4 xl:px-32 mb-4 md:mb-0">

                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="text-xl md:text-2xl lg:text-3xl  md:pt-9 p-6 md:pl-0 md:pr-0 md:pb-0 md:mb-16 md:mr-20 ">

                  Over 31,000 members of the public attended the exhibition in its first 6 weeks, with 5000 signing up
                  to prolonged engagement in the driverless futures project that influenced its themes and
                  interpretation.
                </p>



              </div>

              <div class="w-full xl:w-6/12 py-4 mb-4 md:mb-0">


                <img class="" src="https://ik.imagekit.io/usi/project-pages/Transport_Museum-1490_1_JoPfN0IJTaSF.png"/>

              </div>
            </div>
          </section>
        </div>
      </section>




      <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/Transport_Museum-1360__E6L0zvRb.jpg"
        alt="titanic"></img>

<div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-blue-900">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../whitley" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
